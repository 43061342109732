<template>
	<component :is="flexibleFieldName"
			   :data="props.data" />
</template>

<script setup>
const props = defineProps({
	data: {
		type: Object,
		required: true
	}
})

const flexibleFieldName = 'flexible-' + componentName(props.data.__component)

function componentName(component) {
	let componentName = component

	if (component.includes('.')) {
		componentName = component.toString().split('.')
		const length = componentName.length
		componentName = componentName[length - 1]

		return componentName
	} else {
		return componentName
	}
}
</script>
